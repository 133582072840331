import type { FC } from 'react';
import type { IconProps } from './Icon.type';

import StarFilled32 from '@andes/icons/StarFilled32';
import VisibilityOFF24 from '@andes/icons/VisibilityOFF24';
import VolumeOff24 from '@andes/icons/VolumeOff24';
import VolumeOn24 from '@andes/icons/VolumeOn24';
import Settings24 from '@andes/icons/Settings24';
import Close20 from '@andes/icons/Close20';
import ArrowLeft20 from '@andes/icons/ArrowLeft20';
import OverflowMenuVertical16 from '@andes/icons/OverflowMenuVertical16';
import DeleteIcon from '@andes/icons/Delete16';
import InfoIcon from '@andes/icons/Info16';
import Drawer24 from '@andes/icons/Drawer24';
import Copy24 from '@andes/icons/Copy24';
import PadlockClosed16 from '@andes/icons/PadlockClosed16';

import { PlayIcon } from './custom/PlayIcon';
import { RelevantIcon } from './custom/RelevantIcon';
import { OvalIcon } from './custom/OvalIcon';
import { PlayKeepWatching } from './custom/PlayKeepWatching';
import { StartOver } from './custom/StartOver';
import { PlayAssetFrame } from './custom/PlayAssetFrame';
import { LockedIcon } from './custom/LockedIcon';
import { UnLockedIcon } from './custom/UnLockedIcon';
import { PlayVCMIcon } from './custom/PlayVCMIcon';
import { PauseVCMIcon } from './custom/PauseVCMIcon';
import { CloseVCMIcon } from './custom/CloseVCMIcon';
import { MuteVCMIcon } from './custom/MuteVCMIcon';
import { UnmuteVCMIcon } from './custom/UnmuteVCMIcon';
import { StartOverVCMIcon } from './custom/StartOverVCMIcon';
import { Blocked } from './custom/Blocked';
import { ChevronDown, ChevronUP } from './custom/Chevron';
import { MeliPlusIcon } from './custom/MeliPlusIcon';
import { DisneyLogoIcon } from './custom/DisneyLogoIcon';
import { HboLogoIcon } from './custom/HboLogoIcon';

export const icon = {
  visibility_off: VisibilityOFF24,
  star: StarFilled32,
  volumeOff: VolumeOff24,
  settings: Settings24,
  close: Close20,
  backbutton: ArrowLeft20,
  playerVolumeOn: VolumeOn24,
  mplay_play: PlayIcon,
  VisibilityOFF24,
  view_relevant: RelevantIcon,
  mplay_oval: OvalIcon,
  mplay_keepwatching_play: PlayKeepWatching,
  OverflowMenuVertical16,
  mplay_delete: DeleteIcon,
  mplay_information: InfoIcon,
  mplay_overflow_vertical_menu: OverflowMenuVertical16,
  start_over: StartOver,
  play_asset_frame: PlayAssetFrame,
  info: Drawer24,
  copy: Copy24,
  locked: LockedIcon,
  padLock: PadlockClosed16,
  unlocked: UnLockedIcon,
  playVCM: PlayVCMIcon,
  pauseVCM: PauseVCMIcon,
  closeVCM: CloseVCMIcon,
  muteVCM: MuteVCMIcon,
  unmuteVCM: UnmuteVCMIcon,
  startOverVCM: StartOverVCMIcon,
  mplay_blocked: Blocked,
  chevron_up: ChevronUP,
  chevron_down: ChevronDown,
  meli_plus: MeliPlusIcon,
  disney_logo: DisneyLogoIcon,
  hbo_logo: HboLogoIcon,
};

const namespace = 'icon';

export const Icon: FC<IconProps> = ({ content, className, color }) => {
  const Icon = icon[content];

  if (Icon) {
    return (
      <Icon
        data-testid={`${namespace}-${content}`}
        color={color}
        className={className}
      />
    );
  }

  return null;
};
